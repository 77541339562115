.title {
    text-align: center;
    font-size: 1.75rem;
    font-weight: bold;
    color: #fcac25;
    margin-bottom: 1rem;
    padding-bottom: 0.6rem;
    border-bottom: 2px solid #c67623;
}
.card_wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 0.5rem;
    row-gap: 0.5rem;
}
@media screen and (max-width: 800px) {
    .title {
        font-size: 1.6rem;
        margin-bottom: 0.9rem;
        padding-bottom: 0.5rem;
    }
    .card_wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 0.4rem;
        row-gap: 0.4rem;
    }
}
@media screen and (max-width: 480px) {
    .title {
        font-size: 1.5rem;
        margin-bottom: 0.6rem;
        padding-bottom: 0.4rem;
    }
    .card_wrapper {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        column-gap: 0.4rem;
        row-gap: 0.4rem;
    }
}