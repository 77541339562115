.bug {
    box-sizing: border-box;
    display: flex;
    width: fit-content;
    height: fit-content;
    align-items: center;
    color: #ff5555;
    font-size: 0.9rem;
}
.bug > a {
    text-decoration: none;
    cursor: pointer;
    color: #ff5555;
}
.bug.withBackground {
    background-color: rgba(170, 20, 20, 0.3);;
}
.bug.withBorder {
    border: 1px solid #ff3535;
    border-radius: 0.5rem;
    padding: 1px 8px;
}
@media screen and (max-width: 720px) {
    .bug {
        font-size: 0.8rem;
    }
    .bug.withBorder {
        border-radius: 0.35rem;
        padding: 0.1rem 0.25rem;
    }
}