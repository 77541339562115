.rst_wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 0.25rem;
    column-gap: 0.5rem;
}
.rst_wrap button {
    border-radius: 0.25rem;
    font-size: 1rem;
    padding: 0.1rem 0.4rem;
}
.rst {
    background-color: #776767;
    border: 2px solid #ff7878;
    color: #ffffff;
}
.rst:hover {
    background-color: #aa9a9a;
}
.undo {
    background-color: #676777;
    border: 2px solid #7878ff;
    color: #ffffff;
}
.undo:hover { background-color: #9a9aaa; }
.undo:disabled {
    background-color: #474757;
    color: #878787;
    border: 2px solid #4848af;
}
.ai_on {
    background-color: #555555;
    border: 2px solid #ff55ff;
    color: #55ff55;
}
.ai_on:hover {
    background-color: #777777;
    border: 2px solid #dd33dd;
    color: #aaff55;
}
.ai_off {
    background-color: #444444;
    border: 2px solid #aa00aa;
    color: #ff5555;
}
.ai_off:hover {
    background-color: #666666;
    border: 2px solid #cc22cc;
    color: #ffaa55;
}

.rst.dark { 
    border: 2px solid #650000;
    background-color: #454545;
}
.rst.dark:hover { background-color: #343434; }
.undo.dark {
    background-color: #454545;
    border: 2px solid #000065;
}
.undo.dark:hover { background-color: #343434; }
.undo.dark:disabled {
    background-color: #232323;
    color: #545454;
    border: 2px solid #232354;
}
