.top {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 0.75rem;
}
.list {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
}
.show, .hide {
    cursor: pointer;
    font-size: 1rem;
    text-decoration: underline;
    user-select: none;
}
.show {
    color: #88ff88;
}
.hide {
    color: #ff8888;
}
@media screen and (max-width: 720px) {
    .show, .hide { font-size: 0.9rem; }
    .description {
        font-size: 0.9rem;
    }
    .top {
        row-gap: 0.35rem;
    }
    .list {
        column-gap: 0.4rem;
    }
}