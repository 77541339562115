.grid {
    display: grid;
    grid-auto-flow: row;
    background: #999999;
    border-radius: 5px;
    transform-origin: top left;
}
.tile_row {
    display: flex;
    flex-direction: row;
}
.col {
    background: #aaaaaa;
    border-radius: 4px;
}
.tile {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 4px;
    word-break: break-all;
    text-align: center;
    color: #ffffff;
}

.grid.dark { background-color: #454545; }
.col.dark { background: #676767; }

@media screen and (max-width: 720px) {
    .grid {
        border-radius: 3px;
    }
    .col, .tile {
        border-radius: 2px;
    }
}