.modalOverlay {
    box-sizing: border-box;
    position: absolute;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
}
.modal {
    box-sizing: border-box;
    width: 33.3%;
    display: flex;
    flex-direction: column;
    row-gap: 0.4rem;
    border-radius: 0.5rem;
    padding: 1rem;
    background-color: rgba(26, 38, 70, 0.9);
    border: 1px solid rgb(52, 76, 120);
}
.modalFooter {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
}
.modalBtn {
    width: max-content;
    justify-self: center;
    font-size: 1.1rem;
    border: 1px solid;
    border-radius: 0.3rem;
    padding: 0.3rem 0.6rem;
    cursor: pointer;
    transition: all ease 0.3s;
}
.cancel {
    color: #ffcccc;
    border-color: #ff5555;
    background-color: rgba(255, 0, 0, 0.15);
}
.cancel:hover { background-color: rgba(255, 0, 0, 0.35); }
.close {
    color: #ffffbb;
    border-color: #ffff55;
    background-color: rgba(255, 224, 0, 0.15);
}
.close:hover { background-color: rgba(255, 224, 0, 0.35); }
.start {
    color: #ccffcc;
    border-color: #55ff55;
    background-color: rgba(0, 255, 0, 0.15);
}
.start:hover { background-color: rgba(0, 255, 0, 0.35); }

@media screen and (max-width: 720px) {
    .modal {
        width: calc(100% - 1rem);
        row-gap: 0.4rem;
        padding: 0.6rem;
    }
}