.nav {
    width: 100%;
    box-sizing:border-box;
    padding: 1rem 3rem;
    background-color: rgba(0, 137, 151, 0.4);
    box-shadow: 0 0 1rem 0 rgba(0, 137, 151, 0.4);
    display: grid;
    grid-auto-flow: column;
    position: relative;
    z-index: 1;
}
.nav_title {
    font-size: 1.35rem;
    color: #ffeedd;
}
.nav_links {
    justify-self: right;
    display: grid;
    grid-auto-flow: column;
    column-gap: 1rem;
    font-size: 1.1rem;
}
a {text-decoration: none;}
.nav_link {
    color: #ffffff;
    transition: color 0.5s;
}
.nav_link:hover {
    color: #ffcf9f;
    transition: color 0.5s;
}
.nav_link.active {
    font-weight: bold;
    color: #ffffff;
}
.nav_link.red {
    color: #ffbbbb;
}
@media screen and (max-width: 770px) {
    .nav {
        grid-auto-flow: row;
        padding: 0.5rem;
        text-align: center;
        row-gap: 0.5rem;
    }
    .navMenu {
        display: grid;
        grid-template-columns: repeat(2, auto);
        column-gap: 1rem;
        align-items: center;
    }
    .menuIcon {
        font-size: 26px;
        justify-self: right;
    }
    .nav_title {
        font-size: 1.25rem;
        justify-self: left;
    }
    .nav_links {
        width: 100%;
        justify-self: center;
        grid-auto-flow: row;
        font-size: 0.95rem;
        row-gap: 0.2rem;
    }
    .nav_link {
        display: block;
        box-sizing: border-box;
        width: 100%;
        border-top: 1px solid #014242;
        padding-top: 0.1rem;
    }
}