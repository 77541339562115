.left {
	display: flex;
	padding: 1.5rem 0;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: transparent;
	border-radius: 1rem;
}

.form_container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.form_container h1 {
	font-size: 2rem;
	margin-top: 0;
	color: #dfefff;
}

.input {
	outline: none;
	border: none;
	width: 100%;
	padding: 0.8rem;
	border-radius: 0.5rem;
	background-color: rgba(25, 145, 240, 0.85);
	color: rgb(255, 220, 80);
	margin: 0.2rem 0;
	font-size: 0.9rem;
}

.error_msg {
	width: 100%;
	padding: 0.6rem;
	margin: 0.2rem 0;
	font-size: 0.9rem;
	background-color: rgba(255, 15, 15, 0.15);
	color: #ff2323;
	border: 1px solid #ff2323;
	border-radius: 0.3rem;
	text-align: center;
}

.green_btn {
	border: none;
	outline: none;
	background-color: #2bacec;
	border-radius: 1.5rem;
	padding: 0.7rem 2.5rem;
	font-weight: bold;
	font-size: 1rem;
	cursor: pointer;
	color: white;
	margin: 0.5rem;
	transition: all 0.5s;
}

.green_btn:hover {
	background-color: #5becff;
}

.link {
	padding: 0 0.25rem;
	font-size: 0.8rem;
	color: #ffeedd;
}
.link a {
	text-decoration: underline;
	color: #bbddff;
}

@media screen and (max-width: 720px) {
	.form_container h1 { font-size: 1.4rem; }
	.input {
		width: 90%;
		font-size: 0.8rem;
	}
	.error_msg {
		width: 90%;
		font-size: 0.8rem;
	}
	.green_btn {
		font-size: 0.9rem;
		border-radius: 1.2rem;
		padding: 0.6rem 2.25rem;
		margin: 0.4rem;
	}
}