.disabled {
    box-sizing: border-box;
    display: flex;
    width: fit-content;
    height: fit-content;
    align-items: center;
    color: #ff9999;
    background-color: rgba(200, 0, 0, 0.15);;
    border: 1px solid #ff5555;
    border-radius: 0.5rem;
    padding: 1px 8px;
    font-size: 0.9rem;
}
@media screen and (max-width: 720px) {
    .disabled {
        font-size: 0.8rem;
        border-radius: 0.35rem;
        padding: 0.1rem 0.25rem;
    }
}