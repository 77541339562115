.tile {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 4px;
    word-break: break-all;
    text-align: center;
    color: #ffffff;
}
.tile_1, .tile_2 {color: #000000;}
.tile_1 {background-color: #ffeecc;}
.tile_2 {background-color: #ffccaa;}
.tile_3 {background-color: #ffaa88;}
.tile_4 {background-color: #ff6644;}
.tile_5 {background-color: #ff4422;}
.tile_6 {background-color: #ff2200;}
.tile_7 {background-color: #ddc063;}
.tile_8 {background-color: #e0bd53;}
.tile_9 {background-color: #e3ba43;}
.tile_10 {background-color: #e6b733;}
.tile_11 {background-color: #e9b423;}
.tile_12 {background-color: #ecb103;}
.tile_13 {background-color: #00c000; box-shadow: 0 0 5px 2px rgba(0, 255, 0, 0.6);}
.tile_14 {background-color: #01c022; box-shadow: 0 0 5px 2px rgba(1, 255, 20, 0.6);}
.tile_15 {background-color: #02c040; box-shadow: 0 0 5px 2px rgba(2, 255, 40, 0.6);}
.tile_16 {background-color: #03c160; box-shadow: 0 0 5px 2px rgba(3, 255, 60, 0.6);}
.tile_17 {background-color: #04c180; box-shadow: 0 0 5px 2px rgba(4, 255, 80, 0.6);}
.tile_18 {background-color: #04c1a0; box-shadow: 0 0 5px 2px rgba(4, 255, 100, 0.6);}
.tile_19 {background-color: #0581c2; box-shadow: 0 0 5px 2px rgba(5, 255, 226, 0.6);}
.tile_20 {background-color: #056dc2; box-shadow: 0 0 5px 2px rgba(5, 235, 226, 0.6);}
.tile_21 {background-color: #0559c2; box-shadow: 0 0 5px 2px rgba(5, 215, 226, 0.6);}
.tile_22 {background-color: #0545c2; box-shadow: 0 0 5px 2px rgba(5, 195, 226, 0.6);}
.tile_23 {background-color: #0531c2; box-shadow: 0 0 5px 2px rgba(5, 175, 226, 0.6);}
.tile_24 {background-color: #051dc2; box-shadow: 0 0 5px 2px rgba(5, 155, 226, 0.6);}
.tile_25 {background-color: #4605c2; box-shadow: 0 0 5px 2px rgba(100, 5, 226, 0.6);}
.tile_26 {background-color: #5a05c2; box-shadow: 0 0 5px 2px rgba(115, 5, 226, 0.6);}
.tile_27 {background-color: #6e05c2; box-shadow: 0 0 5px 2px rgba(125, 5, 226, 0.6);}
.tile_28 {background-color: #8205c2; box-shadow: 0 0 5px 2px rgba(140, 5, 226, 0.6);}
.tile_29 {background-color: #9605c2; box-shadow: 0 0 5px 2px rgba(155, 5, 226, 0.6);}
.tile_30 {background-color: #aa05c2; box-shadow: 0 0 5px 2px rgba(170, 5, 226, 0.6);}
.tile_31 {background-color: #524205; box-shadow: 0 0 5px 2px rgba(170, 150, 5, 0.6);}
.tile_32 {background-color: #423604; box-shadow: 0 0 5px 2px rgba(187, 154, 5, 0.6);}
.tile_33 {background-color: #362d03; box-shadow: 0 0 5px 2px rgba(204, 158, 5, 0.6);}
.tile_34 {background-color: #2a2402; box-shadow: 0 0 5px 2px rgba(221, 162, 5, 0.6);}
.tile_35 {background-color: #1e1b01; box-shadow: 0 0 5px 2px rgba(238, 166, 5, 0.6);}
.tile_36 {background-color: #121200; box-shadow: 0 0 5px 2px rgba(255, 170, 5, 0.6);}
.tile_0 {display: none !important;}