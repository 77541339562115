.content {
    color: white;
}
.titleWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 1rem;
    padding-bottom: 0.8rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid rgb(26, 80, 140);
}
.displayName {
    font-size: 1.5rem;
}
.contentWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
}
.description {
    font-size: 1.1rem;    
}
.betaWarning {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 0.5rem;
    row-gap: 0.4rem;
    align-items: center;
}
.betaWarning > span {
    color: #ffeebb;
}
.downloadBtn {
    color: white;
    background-color: rgba(48, 170, 255, 0.4);
    cursor: pointer;
    border-radius: 0.3rem;
    border: 1px solid #30aaff;
    transition: all ease 0.3s;
    width: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.4rem;
    font-size: 1.1rem;
    padding: 0.3rem 0.6rem;
}
.downloadBtn:hover {
    background-color: rgba(48, 170, 255, 0.6);
}
@media screen and (max-width: 720px) {
    .titleWrapper {
        column-gap: 0.5rem;
        padding-bottom: 0.5rem;
        margin-bottom: 0.35rem;
    }
    .displayName {
        font-size: 1.2rem;
    }
    .description {
        font-size: 1rem;
    }
    .betaWarning {
        font-size: 0.9rem;
    }
    .downloadBtn {
        font-size: 1rem;
    }
}