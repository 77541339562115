.container {
    display: flex;
    flex-direction: column;
}
.title {
	text-align: center;
	align-self: center;
    font-size: 1.75rem;
    font-weight: bold;
    color: #fcac25;
    margin: 0.6rem 1rem;
}
.subtitle {
	text-align: center;
    font-size: 1.3rem;
    font-weight: bold;
    color: #fccc35;
    margin: 0.6rem 1rem;
}
.legend {
	border-collapse: collapse;
}
.legend th {
	padding: 0.1rem 0.4rem;
	border: 1px solid #55ffff;
    border-bottom: 2px solid #23caca;
	background-color: rgba(60, 45, 0, 0.7);
    color: #ffdeca;
}
.legend td {
	border: 1px solid #23abab;
	background-color: rgba(0, 40, 40, 0.7);
    color: #deffff;
}
.btn_green {
    padding: 0.1rem 0.4rem;
    cursor: pointer;
    color: #ffffff;
    border: 1px solid #00ff00;
    border-radius: 0.35rem;
    background: rgba(0, 255, 0, 0.4);
}
.btn_green:hover {
    background: rgba(0, 255, 0, 0.55);
}