.footer > footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: grid;
    grid-auto-flow: column;
    place-items: center;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    color: #ffffff;
    height: fit-content;
    background: linear-gradient(0deg, rgba(66, 66, 66, 1), rgba(66, 66, 66, 0.7));
    box-shadow: 0 -0.5em 0.2em 0.2em rgba(66, 66, 66, 0.7);
    padding-bottom: 0.5rem;
    z-index: 1;
}
.bottom_redux {
    display: block;
    height: 2rem;
}
@media screen and (max-width: 600px) {
    .footer > footer {
        column-gap: 1.5em;
        font-size: 0.65em;
        box-shadow: 0 -0.35em 0.15em 0.15em rgba(66, 66, 66, 0.7);
    }
}