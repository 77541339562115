.wrapper, .inner {
    box-sizing: border-box;
    padding: 0;
    border-radius: 0.35rem;
}
.wrapper {
    width: 100%;
    height: 16px;
    border: 1px solid white;
    background-color: rgba(255, 255, 255, 0.5);
}
.inner {
    height: 100%;
}