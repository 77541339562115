.wrapper {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    max-height: 5px;
    overflow: visible;
}
.event {
    position: relative;
    z-index: 2;
    box-sizing: border-box;
    margin: 0;
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.1rem;
    justify-content: center;
    align-items: center;
    color: white;
}